export function truncateText(text, maxLength = 59, suffix = '') {
    if (text.length <= maxLength) return text.trim();

    const truncated = text.substr(0, maxLength - suffix.length + 1)
        .split(/\s/)
        .slice(0, -1)
        .join(' ')
        .trim();

    return `${truncated}${suffix}`
}