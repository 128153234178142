import React from 'react';
import PropTypes from 'prop-types';

import Helmet from '../../helmet';
import { truncateText } from '../../../utils';

const TITLE_MAX_LENGTH = 60;
const DESCRIPTION_MAX_LENGTH = 160;

const LONG_DESCRIPTION_SUFFIX = '- jetzt bei uns beraten lassen und hier bestellen!';
const SHORT_DESCRIPTION_SUFFIX = '- hier bestellen!';

const Head = ({
    product: { name: productName },
    metaTitle,
    metaDescription = '',
    metaCanonical = '',
    imageUrl,
    children,
}) => {
    const cleanProductName = productName.trim();
    const title = truncateText(metaTitle, TITLE_MAX_LENGTH);
    let description = `${cleanProductName} ${LONG_DESCRIPTION_SUFFIX}`;

    if (description.length > DESCRIPTION_MAX_LENGTH) {
        description = `${cleanProductName} ${SHORT_DESCRIPTION_SUFFIX}`.length <= DESCRIPTION_MAX_LENGTH 
            ? `${cleanProductName} ${SHORT_DESCRIPTION_SUFFIX}` 
            : truncateText(cleanProductName, DESCRIPTION_MAX_LENGTH);
    }

    const seoTags = [
        {
            tagName: 'title',
            content: title,
        },
        {
            tagName: 'meta',
            attributes: { property: 'og:title', content: title },
        },
        {
            tagName: 'meta',
            attributes: { name: 'description', content: description },
        },
        {
            tagName: 'meta',
            attributes: { property: 'og:description', content: description },
        },
        {
            tagName: 'meta',
            attributes: { property: 'og:image', content: imageUrl },
        },
    ];

    return (
        <Helmet
            tags={{ tags: seoTags }}
            metaRobots=""
            metaCanonical={metaCanonical}
            ogType="product"
            title={title}
            description={description}
        >
            {process.env.GATSBY_IMGPROXY_BASEURL && (
                <link rel="preconnect" href={process.env.GATSBY_IMGPROXY_BASEURL} />
            )}

            {imageUrl && (
                <link rel="preload" href={imageUrl} as="image" />
            )}

            {children}
        </Helmet>
    );
};

Head.defaultProps = {
    imageUrl: undefined,
    metaDescription: '',
    metaCanonical: '',
    children: null,
};

Head.propTypes = {
    metaTitle: PropTypes.string.isRequired,
    metaDescription: PropTypes.string,
    metaCanonical: PropTypes.string,
    imageUrl: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
};

export default Head;
